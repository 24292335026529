import {
  Button,
  Group,
  NativeSelect,
  ScrollArea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedCallback } from "@mantine/hooks";
import React, { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
// import Select from 'react-select'

// const statusOptions = [
//     {'value':'', 'label':'All'},
//     {'value':'Active', 'label':'Active'},
//     {'value':'Inactive', 'label':'Inactive'},
//     {'value':'Pending', 'label':'Pending'},
// ]

const UsersFilter = ({ onClickFilterCallback, defaultKeyword, state }) => {
  const form = useForm({});

  const onEnterpress = (e) => {
    if (e.key === "Enter") {
      form.validate();
    }
  };

  const submit = useDebouncedCallback(async () => {
    onClickFilterCallback(form.values);
  }, 500);

  useEffect(() => {
    form.setValues({query: '', status: ''});
    onClickFilterCallback({query: '', status: ''});
  }, [state]);


  return (
    <form onChange={form.onSubmit((values) => submit(values))} onSubmit={form.onSubmit((values) => submit(values))}>
      <Group justify="space-between" wrap="nowrap">
        <TextInput
          size="sm"
          style={{ marginBottom: "0" }}
          onKeyDown={onEnterpress}
          placeholder="Search Patients"
          {...form.getInputProps("query")}
        />
        <NativeSelect
          placeholder="Status"
          style={{ marginBottom: "0" }}
          size="sm"
          mb={0}
          data={[
            { value: "", label: "All Statuses" },
            { value: "processing", label: "Processing" },
            { value: "done", label: "Done" },
          ]}
          {...form.getInputProps("status")}
        />
        {(form.values.query || form.values.status) && <Button variant="outline" onClick={() => {
          form.setValues({query: '', status: ''});
          onClickFilterCallback({query: '', status: ''})
        }} size="xs" color="red">
          <FaTimes></FaTimes> Clear
        </Button>}
      </Group>
    </form>
  );
};

export default UsersFilter;
